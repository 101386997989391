<template>
  <base-section id="partner">
      <base-section-heading title="Perusahaan Asuransi Rekanan" class="text-uppercase"/>
    <div 
    data-aos="fade-right"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
    data-aos-anchor-placement="top-center">
    <v-container>
      <template>
        <v-row>
        <v-col
          v-for="data in posts"
          :key="data.partner_logo"
          cols="3"
          xs="3"
          md="1"
          sm="3"
          class="pa-1"
        >
        <v-card
          class="d-flex align-center justify-center cursor rounded-lg"
          width="150"
          height="70"
          outlined
        >
           <v-img :src="require(`@/assets/${data.partner_logo}`)" cover/>
        </v-card>
        </v-col>
      </v-row>
      </template>
    </v-container>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPartner',
    data: () => ({
      posts: [],
      snackbar: {
        value: false,
        text: ''
      },
    }),
    created () {
        this.List()
    },
    methods: {
      List () {
        this.posts = [
                      {'partner_logo':'partner-insurance-01.png'},
                      {'partner_logo':'partner-insurance-02.png'},
                      {'partner_logo':'partner-insurance-03.png'},
                      {'partner_logo':'partner-insurance-04.png'},
                      {'partner_logo':'partner-insurance-05.png'},
                      {'partner_logo':'partner-insurance-06.png'},
                      {'partner_logo':'partner-insurance-07.png'},
                      {'partner_logo':'partner-insurance-08.png'},
                      {'partner_logo':'partner-insurance-09.jpeg'},
                      {'partner_logo':'partner-insurance-10.png'},
                      {'partner_logo':'partner-insurance-11.png'},
                      {'partner_logo':'partner-insurance-12.png'},
                      {'partner_logo':'partner-insurance-13.png'},
                      {'partner_logo':'partner-insurance-14.jpg'},
                      {'partner_logo':'partner-insurance-15.png'},
                      {'partner_logo':'partner-insurance-16.png'},
                      {'partner_logo':'partner-insurance-17.jpg'},
                      {'partner_logo':'partner-insurance-18.png'},
                      {'partner_logo':'partner-insurance-19.png'},
                      ]
      },
    }
  }
</script>
